import React from 'react'

const Hero = ({data}) => {

  const renderButton = (button) => {
    if(button.url) { 
      return (<a href={button.url} className={`heroButton${button.appointment ? ' appointment' : ''}`} target="_blank">{button.title}</a>)
    } else {
      return <div className="heroButton">{button.title}</div>
    }
  }

  return (
    <div style={{ background: `url(${data.backgroundImage.fluid.src}) no-repeat` }} className="topHero">
      <div className="heroContainer">
        {data.subTitle ? <div className="heroSubTitle">{data.subTitle}</div> : ''}
        <div className="heroTitle text-center"><h2>{data.title}</h2></div>
        {data.heroButton && renderButton(data.heroButton)}
      </div>
    </div>
  )
} 

export default Hero