import React from 'react'
import  Mailchimp  from 'react-mailchimp-form' 
import sidebarImg from '../images/sidebar-img.jpg'

const Sidebar = () => {
  return (
    <div className={'sidebar'}>
      <img src={sidebarImg} alt="Ready to perform at your best?" />
      <div className="schedule-appointment">
        <a href={'https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=ad4fbee7-5eab-4efa-9cfa-8135ba772200'} className={`heroButton appointment`} target="_blank">Schedule an Appointment</a>
      </div>
      <div className="newsletter">
					<h2 className="text-center">Join Our Newsletter</h2>
					<div className="input-group mb-3 newsletter-form-control">
						<label className="d-none" htmlFor="email-input-footer">Input a valid e-mail address.</label>
						<Mailchimp
							className="form-control"
							action='https://sdsportsacu.us7.list-manage.com/subscribe/post?u=f1027f9738a55e44f7939241a&amp;id=3c8b210102'
							fields={[
								{
									name: 'EMAIL',
									placeholder: 'Email Address',
									type: 'email',
									required: true
								}
							]}
							messages={
								{ 
									sending: "Sending...",
									success: "Thank you for subscribing!",
									error: "An unexpected internal error has occurred.",
									empty: "You must write an e-mail.",
									duplicate: "Too many subscribe attempts for this email address",
									button: "Sign Up"
								}
							}
						/>
					</div>
				</div>
    </div>
  )
}

export default Sidebar