import React, { Component } from 'react'
import { navigate } from "@reach/router";
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout'
import { Container, Col, Row } from 'react-bootstrap'
import Sidebar from '../components/sidebar'
import RenderRichText from '../components/renderRichText'
import CaseStudies from '../components/caseStudies'
import Hero from '../components/hero'
import SEO from '../components/seo'

class Details extends Component {
  render() {
    const detailData = this.props.pageContext.data;
    const categoryData = this.props.pageContext.category.filter(el => el.node.slug === 'case-studies')
    const { footerHero } = categoryData[0].node;
    const relatedData = [];

    return (
      <Layout>
        { <SEO title={detailData.seoTitle && detailData.seoTitle} description={detailData.seoDescription && detailData.seoDescription} /> }
        <div className="detailsPage">
        <div style={{ background: `url(${detailData.featuredImage.fluid.src}) no-repeat center` }} className="topHero">
          <div className="heroContainer">
            <div className="heroSubTitle">Case Study</div>
            <div className="heroTitle text-center"><h1>{detailData.entryTitle}</h1></div>
          </div>
        </div>
        <Container>
          <div className="case-study-richtext">
            <a className="back-button" onClick={() => navigate(-1)}>&laquo; Back to Case Studies</a>
            <Row>
            <div className={"col-xl-8 col-12"}>
              <RenderRichText data={detailData.content.json} />
            </div>
            <div className={"col-lg-4 d-none d-xl-block"}>
              <Sidebar />
            </div>
            </Row>
          </div>
        </Container>

        <div className="article-list case-studies">
          <h2 className="text-center">More Articles</h2>
          <div className="container">
          <div className="row articles">
        <StaticQuery query={graphql` 
          query {
            allContentfulCaseStudy(limit: 3) {
              edges {
                node {
                  entryTitle
                  slug
                  featuredImage {
                    fixed(width: 395, height: 220, cropFocus: CENTER)  {
                      src
                    }
                    title
                    description
                  }
                  date(formatString: "MMMM DD YYYY")
                  content {
                    json
                  }
                  categories
                  type
                }
              }
            }
          }
        `}
        render={data => (
          data.allContentfulCaseStudy.edges.forEach(element => {
            relatedData.push(element.node);
          }),
          <CaseStudies data={relatedData} />
        )}/>
        </div></div></div>
        <div className="bottomhero">{ footerHero && <Hero data={footerHero} /> }</div>
        </div>
      </Layout>
    )
  }
}

export default Details